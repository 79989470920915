import { GetterTree } from 'vuex';
import cloneDeep from 'lodash-es/cloneDeep';
import { Barn, Batch, Farm, KpiType, Form, Record, Event, UserRole, DirectoryFarm, DirectoryBarn, Solution, Section, Module, DirectorySection, Notification, PigType } from '@/types';
import { State } from '@/plugins/store/state';

export type Getters = {
    getNotifications(state: State): Notification[];
    getLocations(state: State): (withBarns: boolean, withSections: boolean, expandBatches: boolean) => Farm[];
    getDirectoryFarms(state: State): (withBarns: boolean, withSections: boolean) => DirectoryFarm[];
    getTableRecords(state: State): (recordTypeIds: number[]) => (Record & { icon_url: string | undefined; name: string | undefined })[];
    getSelectedSiteIds(state: State): number[];
    getSelectedSiteIdsFilteredByPigType(state: State): number[];
    getSelectedPeriod(state: State): string;
    loggedInUserCanManageDirectory(state: State): boolean;
    loggedInUserCanManageSolution(state: State): boolean;
    directoryHasBatchBarns(state: State): boolean;
    hasModuleEnabled(state: State): (module: Module) => boolean;
    activeDirectoryName(state: State): string;
    getIsWeaners(state: State): boolean;
};

export const getters: GetterTree<State, State> & Getters = {
    getNotifications(state) {
        return state.notificationResponse?.data || [];
    },

    getLocations: (state) => (withBarns: boolean, withSections: boolean, expandBatches: boolean) => {
        const farms = cloneDeep(state.farms);
        if (!withBarns) {
            return farms;
        }
        const barns = cloneDeep(state.barns);
        type BarnGroup = {
            [key: string]: (Barn | Batch)[];
        };
        type SectionGroup = {
            [key: string]: Section[];
        };

        const groupedBarns: BarnGroup = barns.reduce((accum, current) => {
            if (expandBatches && current.is_batch_production) {
                (accum[current.farm_id] = accum[current.farm_id] || []).push(...current.open_batches);
            } else {
                (accum[current.farm_id] = accum[current.farm_id] || []).push(current);
            }
            return accum;
        }, {} as BarnGroup);
        const groupedSections: SectionGroup = withSections
            ? cloneDeep(state.sections || [])?.reduce((accum: any, current) => {
                  (accum[current.barn_id] = accum[current.barn_id] || []).push(current);
                  return accum;
              }, {} as SectionGroup)
            : ({} as SectionGroup);

        Object.values(groupedSections).forEach((sectionGroup) => {
            const barn = barns.find((currentBarn) => currentBarn.barn_id === sectionGroup[0].barn_id);
            if (!barn) {
                return;
            }
            barn.sections = sectionGroup;
        });
        Object.values(groupedBarns).forEach((barnGroup) => {
            const farm = farms.find((currentFarm) => currentFarm.farm_id === barnGroup[0].farm_id);
            if (!farm) {
                return;
            }
            farm.locations = barnGroup;
        });

        return farms;
    },

    getDirectoryFarms: (state) => (withBarns: boolean, withSections: boolean) => {
        const farms = cloneDeep(state.directoryFarms);

        interface BarnGroup {
            [key: string]: DirectoryBarn[];
        }
        interface SectionGroup {
            [key: string]: DirectorySection[];
        }

        if (!withBarns) {
            return farms;
        }

        const barns = cloneDeep(state.directoryBarns);
        const groupedBarns: BarnGroup = barns.reduce((accum: BarnGroup, current) => {
            (accum[current.farm_id] = accum[current.farm_id] || []).push(current);
            return accum;
        }, {} as BarnGroup);

        Object.values(groupedBarns).forEach((barnGroup) => {
            const farm = farms.find((currentFarm) => currentFarm.id === barnGroup[0].farm_id);
            if (!farm) {
                return;
            }
            farm.barns = barnGroup;
        });

        if (!withSections) {
            return farms;
        }

        const groupedSections: SectionGroup = cloneDeep(state.directorySections).reduce((accum: SectionGroup, current) => {
            (accum[current.barn_id] = accum[current.barn_id] || []).push(current);
            return accum;
        }, {} as SectionGroup);

        Object.values(groupedSections).forEach((sectionGroup) => {
            const barn = barns.find((currentBarn) => currentBarn.id === sectionGroup[0].barn_id);
            if (!barn) {
                return;
            }
            barn.sections = sectionGroup;
        });

        return farms;
    },

    getTableRecords: (state) => (recordTypeIds: number[]) =>
        (state.recordResponse?.data || [])
            .filter((record) => recordTypeIds.includes(record.type_id))
            .map((record) => {
                const recordType = state.recordTypes.find((currentRecordType) => currentRecordType.id === record.type_id);
                return { ...record, icon_url: recordType?.icon_url, name: recordType?.name };
            }),

    getSelectedSiteIds(state) {
        return [...state.selectedFarmIds, ...state.selectedBarnIds, ...state.selectedBatchIds, ...state.selectedSectionIds];
    },

    getSelectedSiteIdsFilteredByPigType(state) {
        const farmIds = state.selectedFarmIds;

        const selectedSiteIds: number[] = [];

        for (const farmId of farmIds) {
            const barns = state.barns.filter((b) => b.farm_id === farmId && state.selectedBarnIds.includes(b.barn_id) && b.is_weaners === (state.pigType === PigType.Weaners));
            if (barns.length) {
                selectedSiteIds.push(farmId);

                for (const barn of barns) {
                    selectedSiteIds.push(barn.barn_id);

                    const batchIds = barn.open_batches.filter((b) => state.selectedBatchIds.includes(b.batch_id)).map((b) => b.batch_id);
                    const sectionIds = state.sections?.filter((s) => state.selectedSectionIds.includes(s.id) && s.barn_id === barn.barn_id).map((s) => s.id) || [];

                    selectedSiteIds.push(...batchIds, ...sectionIds);
                }
            }
        }

        return selectedSiteIds;
    },

    getSelectedPeriod(state) {
        const periodConstituents = state.periodPickerDateString.split(' - ');
        const startDateString = periodConstituents[0];
        const endDateString = periodConstituents.length === 2 ? periodConstituents[1] : periodConstituents[0];

        const startDate = new Date(new Date(Date.parse(startDateString)).setHours(0, 0, 0, 0));
        const endDate = new Date(new Date(Date.parse(endDateString)).setHours(23, 59, 59, 999));

        return `from=${startDate.toISOString()}&to=${endDate.toISOString()}`;
    },

    loggedInUserCanManageDirectory(state) {
        return state.userRole === UserRole.directoryAdmin || state.userRole === UserRole.solutionAdmin;
    },

    loggedInUserCanManageSolution(state) {
        return state.userRole === UserRole.solutionAdmin;
    },

    activeDirectoryName(state) {
        return state.user?.directory_name || '';
    },

    hasModuleEnabled: (state) => (module: Module) => Boolean(state.user?.modules.includes(module)),

    /* Referenced by Vue Router. If renamed, make sure to rename everywhere! */
    directoryHasBatchBarns(state) {
        return Boolean(state.barns.find((currentBarn) => currentBarn.is_batch_production));
    },

    getIsWeaners(state) {
        return state.pigType === PigType.Weaners;
    },
};
