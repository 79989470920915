<template>
    <MainViewCard class="space-y-4">
        <div class="flex mx-6 space-x-6 pt-4">
            <h4 class="text-lg font-medium my-auto flex-1">{{ $t('views.settings.directories.content.table.title') }}</h4>
            <span class="text-right mr-6 w-80">
                <SearchInput v-model:value="search" :label="$t('views.settings.directories.content.table.searchFieldLabel')"></SearchInput>
            </span>
            <SettingsFormModal
                v-if="createDirectorySettingEvent && canCreateDirectores"
                :initial-state="{
                    event: createDirectorySettingEvent,
                    form: null,
                    confirmed: false,
                }"
                @closing="onFormModalClosing"
            >
                <template #default="{ open }">
                    <AddButton text-class="font-medium" @click="open">
                        {{ $t('views.settings.directories.content.createDirectoryButtonLabel') }}
                    </AddButton>
                </template>
            </SettingsFormModal>
        </div>
        <CustomTable expandable :items="filteredItems" :headers="headers">
            <template #header_actions>
                <th class="w-20"></th>
            </template>
            <template #active="{ item, getHeaderClass, getWidth, header }">
                <td class="flex-1" :class="`${getHeaderClass(header, false)} my-auto`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <div class="flex rounded-full h-5 w-5 bg-opacity-20" :class="item.active ? 'bg-green-200' : 'bg-red-400'">
                        <Icon class="m-auto" :src="item.active ? IconSource.Active : IconSource.Inactive"></Icon>
                    </div>
                </td>
            </template>
            <template #search_topics="{ item, getHeaderClass, getWidth, header }">
                <td :key="header.value" :class="`${getHeaderClass(header, false)} my-auto break-words flex space-x-2`" :style="`max-width: ${getWidth(header)}px; width: 100%;`">
                    <DirectoriesMoreInfoTooltip :directory="item" />
                </td>
            </template>
            <template #actions="{ item }">
                <td class="w-20">
                    <div v-if="canManageDirectory || !item.active" class="flex justify-end gap-x-1">
                        <SettingsFormModal :initial-state="editDirectoryInitialState" :step-config="editDirectoryStepConfig" @closing="onFormModalClosing">
                            <template #default="{ open }">
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger as-child>
                                            <button
                                                type="button"
                                                :disabled="loadingDirectoryId !== null"
                                                class="flex items-center justify-center h-8 w-8 rounded-full text-gray-800 hover:text-gray-900 hover:bg-gray-100 disabled:bg-transparent transition-colors"
                                                @click="onEditDirectoryClicked(item, open)"
                                            >
                                                <span class="sr-only">{{ $t('views.settings.directories.content.buttons.viewCompanyDetails') }}</span>
                                                <Eye class="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>{{ $t('views.settings.directories.content.buttons.viewCompanyDetails') }}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </template>
                        </SettingsFormModal>
                        <TooltipProvider v-if="!item.active">
                            <Tooltip>
                                <TooltipTrigger as-child>
                                    <button
                                        type="button"
                                        :disabled="loadingDirectoryId !== null"
                                        class="flex items-center justify-center h-8 w-8 rounded-full text-gray-800 hover:text-gray-900 hover:bg-gray-100 disabled:bg-transparent transition-colors"
                                        @click="onSwitchDirectory(item)"
                                    >
                                        <span class="sr-only">{{ $t('views.settings.directories.content.buttons.switchCompany') }}</span>
                                        <Spinner v-if="loadingDirectoryId === item.id" class="h-5 w-5" />
                                        <RefreshCcw v-else class="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>{{ $t('views.settings.directories.content.buttons.switchCompany') }}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                </td>
            </template>
        </CustomTable>
    </MainViewCard>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick } from 'vue';
import { RefreshCcw, Eye } from 'lucide-vue-next';
import MainViewCard from '@/components/common/MainViewCard.vue';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Directory, SettingEvent, SettingEventType, TableHeader, UserRole, IconSource } from '@/types';
import CustomTable from '@/components/ui/CustomTable.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import SettingsFormModal from '@/components/common/settingsFormModal/SettingsFormModal.vue';
import AddButton from '@/components/ui/AddButton.vue';
import DirectoriesMoreInfoTooltip from '@/views/dashboard/settings/directories/DirectoriesMoreInfoTooltip.vue';
import { i18n } from '@/plugins/internationalization/i18n';
import useFilterableTable from '@/composables/useFilterableTable';
import Spinner from '@/components/ui/Spinner.vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

store.dispatch(ActionType.GetDirectories);
store.dispatch(ActionType.GetActions);
store.dispatch(ActionType.GetSettingEvents);

const editingDirectory = ref<null | Directory>(null);
const editDirectoryInitialState = ref<{
    event: null | SettingEvent;
    form: null;
    confirmed: boolean;
}>({
    event: null,
    form: null,
    confirmed: false,
});

const headers = computed<TableHeader[]>(() => [
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.active'),
        value: 'active',
        align: 'left',
        sortable: true,
        searchable: false,
    },
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.name'),
        value: 'name',
        align: 'left',
        sortable: true,
        searchable: true,
    },
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.mainNumber'),
        value: 'main_number',
        align: 'left',
        sortable: true,
        searchable: true,
    },
    {
        text: i18n.global.t('views.settings.directories.content.table.headers.moreInfo'),
        value: 'search_topics',
        align: 'left',
        sortable: false,
        searchable: true,
        searchComparison: (item: Directory, search) => {
            const searchableSearchTopics = item.search_topics.filter((currentSearchTopic) => currentSearchTopic.searchable);
            const querables = searchableSearchTopics.flatMap((currentSearchTopic) => currentSearchTopic.querables);
            return querables.join(',').toLowerCase().trim().includes(search.toLowerCase().trim());
        },
    },
    {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        searchable: false,
    },
]);
const items = computed(() => store.state.directories);
const { search, filteredItems } = useFilterableTable(items, headers);

const canCreateDirectores = computed(() => store.state.userRole === UserRole.solutionAdmin);
const canManageDirectory = computed(() => store.getters.loggedInUserCanManageDirectory);

const editDirectoryStepConfig = computed(() => [
    { directory: editingDirectory.value },
    {},
    {
        rejectable: false,
        submitActionType: ActionType.PutForm,
        editButtonLabel: i18n.global.t('global.forms.steps.confirmation.backButtonLabel'),
    },
    {},
]);
const createDirectorySettingEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === SettingEventType.directoryCreate));
const editDirectorySettingEvent = computed(() => store.state.settingEvents.find((currentSettingEvent) => currentSettingEvent.type === SettingEventType.directoryData));

function onFormModalClosing(isFinalStep: boolean) {
    if (isFinalStep) {
        store.dispatch(ActionType.GetDirectories, { options: { ignoreCache: true } });
    }
}

async function onEditDirectoryClicked(directory: Directory, open: CallableFunction) {
    editingDirectory.value = directory;

    await nextTick();

    open();
}

watch(
    editDirectorySettingEvent,
    (newValue: SettingEvent | undefined) => {
        if (newValue) {
            editDirectoryInitialState.value.event = newValue;
        }
    },
    { immediate: true, deep: true }
);

const loadingDirectoryId = ref<string | null>(null);

async function onSwitchDirectory(directory: Directory) {
    try {
        if (loadingDirectoryId.value !== null) {
            return;
        }

        loadingDirectoryId.value = directory.id;

        await store.dispatch(ActionType.ActivateDirectory, { form: { directoryId: directory.id } });

        window.location.reload();
    } catch (err) {
        loadingDirectoryId.value = null;
    }
}
</script>
