<template>
    <HelpVideoModal v-if="items.length" :item="activeVideoItem">
        <template #default="{ open }">
            <DropdownMenu :open="isOpen" :modal="false" @update:open="onUpdateOpen">
                <DropdownMenuTrigger as-child class="fixed bottom-5 right-5 bg-primary-800 hover:bg-primary-700 transition-colors rounded-full p-2">
                    <button>
                        <Icon :src="IconSource.HelpIcon" class="w-6 h-6" />
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="min-w-48 space-y-1" side="top" align="end" :side-offset="12" hide-when-detached>
                    <component
                        :is="getItemComponent(item)"
                        v-for="(item, itemIndex) in items"
                        :key="itemIndex"
                        :item="(item as any)"
                        :disabled="isRouteItemLoading"
                        :loading="isRouteItemLoading && routeItemIndexLoading === itemIndex"
                        @select-video="onSelectVideo(item as VideoItemType, open)"
                        @select-route="onSelectRoute(item as RouteItemType, itemIndex)"
                    />
                </DropdownMenuContent>
            </DropdownMenu>
        </template>
    </HelpVideoModal>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick } from 'vue';
import { Module, HelpItemType, HelpWidgetData, HelpWidgetItem, HelpWidgetVideoItem as VideoItemType, HelpWidgetRouteItem as RouteItemType } from '@/types';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { IconSource } from '@/types/IconSource';
import HelpWidgetUrlItem from './items/HelpWidgetUrlItem.vue';
import HelpWidgetVideoItem from './items/HelpWidgetVideoItem.vue';
import HelpVideoModal from '@/components/common/helpWidget/HelpVideoModal.vue';
import HelpWidgetRouteItem from './items/HelpWidgetRouteItem.vue';
import { useGetAndOpenRouteItem } from '@/components/common/helpWidget/composables/useGetAndOpenRouteItem';

const hasHelpWidgetModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.HelpWidget));

const helpWidgetData = ref<HelpWidgetData | null>(null);

const items = computed(() => helpWidgetData.value?.items.filter((i) => i.is_visible) ?? []);

const isOpen = ref(false);

async function getHelpWidgetData() {
    try {
        const data = await store.dispatch(ActionType.GetHelpWidget);

        helpWidgetData.value = data;
    } catch (err) {
        //
    }
}

function getItemComponent(item: HelpWidgetItem) {
    switch (item.type) {
        case HelpItemType.Url:
            return HelpWidgetUrlItem;
        case HelpItemType.Video:
            return HelpWidgetVideoItem;
        case HelpItemType.Route:
            return HelpWidgetRouteItem;
        default:
            return null;
    }
}

function onUpdateOpen(open: boolean) {
    if (isRouteItemLoading.value) {
        return;
    }

    isOpen.value = open;
}

const activeVideoItem = ref<VideoItemType>();

async function onSelectVideo(item: VideoItemType, openModal: CallableFunction) {
    activeVideoItem.value = item;

    await nextTick();

    openModal();
}

const { getAndOpenRouteItem, isLoading: isRouteItemLoading } = useGetAndOpenRouteItem();

const routeItemIndexLoading = ref<number | null>(null);

async function onSelectRoute(item: RouteItemType, itemIndex: number) {
    routeItemIndexLoading.value = itemIndex;

    getAndOpenRouteItem(item, {
        onSuccess: () => {
            isOpen.value = false;
        },
    });
}

const stop = watch(
    hasHelpWidgetModuleEnabled,
    (newValue) => {
        if (newValue) {
            getHelpWidgetData();
            stop();
        }
    },
    { immediate: true }
);
</script>
