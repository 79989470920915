<template>
    <DashboardContentLayout heading="" :has-period-picker="false" :has-pig-type-picker="false">
        <DataQualityBar v-if="hasDataQualityAssessmentModuleEnabled" />
        <div class="space-y-8">
            <KpiGrid></KpiGrid>
            <DashboardContentHeader :heading="$t('views.overview.header.heading')" :has-period-picker="true" :has-pig-type-picker="true"></DashboardContentHeader>
            <GenericDashboard :data="dashboardData" :loading="isDashboardLoading" :fetch-action-type="ActionType.GetDashboardOverview" @update:item="onItemUpdate" @update:section-collapse="onSectionCollapse" />
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import KpiGrid from '@/views/dashboard/overview/KpiGrid.vue';
import DashboardContentHeader from '@/components/layouts/dashboardLayout/content/DashboardContentHeader.vue';
import DataQualityBar from './DataQualityBar.vue';
import { store } from '@/plugins/store';
import { DashboardItem, DashboardSectionWithItems, Module } from '@/types';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { MutationType } from '@/plugins/store/mutations';

useRefetchDataOnDashboardStateChanged(ActionType.GetDashboardOverview, { watchSites: true });

const hasDataQualityAssessmentModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.DataQualityAssessment));
const dashboardData = computed(() => store.state.dashboardOverview);
const isDashboardLoading = computed(() => store.state.dashboardOverviewLoading);

function onItemUpdate({ item, sectionIndex }: { item: DashboardItem; sectionIndex?: number }) {
    const clonedData = store.state.dashboardOverview;

    if (!clonedData) {
        return;
    }

    if (sectionIndex) {
        (clonedData.sections as DashboardSectionWithItems[])[sectionIndex].items[item.item_no] = item;
    } else {
        const itemIndex = clonedData.items.findIndex((i) => item.item_no === i.item_no);

        clonedData.items.splice(itemIndex, 1, item);
    }

    store.commit(MutationType.SetDashboardOverview, clonedData);
}

function onSectionCollapse({ sectionIndex, isCollapsed }: { sectionIndex: number; isCollapsed: boolean }) {
    const clonedData = store.state.dashboardOverview;

    if (!clonedData?.sections) {
        return;
    }

    (clonedData.sections as DashboardSectionWithItems[])[sectionIndex].is_collapsed = isCollapsed;

    store.commit(MutationType.SetDashboardOverview, clonedData);
}
</script>
