<template>
    <MainViewCard>
        <div class="space-y-4 px-4 py-4" :class="{ 'expanded-barn': isExpanded }" style="scroll-margin-top: 120px">
            <div class="flex justify-between items-center space-x-3">
                <div class="flex items-center space-x-3">
                    <div>
                        <Icon class="h-6" :src="IconSource.Location"></Icon>
                    </div>
                    <div>
                        <div class="font-bold text-lg">{{ barn.headers.barn_name }}</div>
                        <div class="text-sm text-gray-500">
                            {{ barn.rows?.length || 0 }} {{ barn.rows?.length === 1 ? $t('views.monitoring.main.headings.section') : $t('views.monitoring.main.headings.sectionPlural') }}
                        </div>
                    </div>
                </div>
                <div v-if="barn.rows && barn.rows.length">
                    <button class="text-sky-500 hover:text-sky-700 transition-colors p-2 -mr-2" @click.prevent="toggleExpandBarn(barn.headers.barn_id)">
                        {{ isExpanded ? $t('views.monitoring.main.buttons.hideSections') : $t('views.monitoring.main.buttons.viewSections') }}
                    </button>
                </div>
            </div>
            <ExpandCollapseTransition>
                <div v-if="isExpanded && barn.rows && barn.rows.length" class="pt-6 space-y-6 border-t border-t-gray-200">
                    <div class="flex justify-between items-center gap-x-3">
                        <div class="text-lg font-medium">
                            {{ isHourly ? $t('views.monitoring.charts.titles.feedingSystemRuntimePerHour') : $t('views.monitoring.charts.titles.feedingSystemRuntimePerDay') }}
                        </div>
                        <div class="flex items-center justify-end gap-x-4">
                            <ChartPeriodSelect v-model="period" :disabled="isLoading || isDelayedLoading" @update:model-value="triggerDelayedLoading" />
                            <SwitchButton
                                v-model="isHourly"
                                :true-text="$t('views.monitoring.main.charts.hourlyLabel')"
                                :false-text="$t('views.monitoring.main.charts.dailyLabel')"
                                :disabled="isLoading || isDelayedLoading"
                                @update:model-value="triggerDelayedLoading"
                            />
                        </div>
                    </div>
                    <FoodChart v-for="row in barn.rows" :key="row.section_id" :row="row" />
                </div>
            </ExpandCollapseTransition>
            <div v-if="!(barn.rows && barn.rows.length)" class="text-center font-medium pt-6 pb-2 border-t border-t-gray-200">
                {{ $t('views.monitoring.main.tables.noSectionsText') }}
            </div>
        </div>
    </MainViewCard>
</template>

<script lang="ts">
import { defineComponent, ref, provide, computed, inject } from 'vue';
import { useIsFetching } from '@tanstack/vue-query';
import MainViewCard from '@/components/common/MainViewCard.vue';
import Icon from '@/components/icons/Icon.vue';
import { ChartPeriod, IconSource, MonitoringDataBarn } from '@/types';
import FoodChart from './FoodChart.vue';
import { FOOD_CHARTS_INJECTION_KEY } from './injectionKeys';
import SwitchButton from '@/components/ui/SwitchButton.vue';
import { queryKeys } from '@/plugins/store/actions/queries/monitoring/charts';
import { MONITORING_STATE } from '../injectionKeys';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import ChartPeriodSelect from '@/views/dashboard/monitoring/monitoring/ChartPeriodSelect.vue';

export default defineComponent({
    components: { MainViewCard, Icon, FoodChart, SwitchButton, ExpandCollapseTransition, ChartPeriodSelect },
    props: {
        barn: {
            type: Object as () => MonitoringDataBarn,
            required: true,
        },
    },
    setup(props) {
        const chartInstances = ref<Record<number, any>>({});
        const isChartsUpdating = ref(false);
        const isHourly = ref(true);
        const isFetching = useIsFetching({ queryKey: queryKeys.feedingSystemRuntimeCharts() });
        const isLoading = computed(() => isFetching.value > 0);
        const isDelayedLoading = ref(false);
        const period = ref(ChartPeriod.THREE_MONTHS);

        const { expandedBarns, toggleExpandBarn } = inject(MONITORING_STATE) as any;

        const isExpanded = computed(() => expandedBarns.value.includes(props.barn.headers.barn_id));

        function triggerDelayedLoading() {
            isDelayedLoading.value = true;

            setTimeout(() => {
                isDelayedLoading.value = false;
            }, 800);
        }

        provide(FOOD_CHARTS_INJECTION_KEY, {
            chartInstances,
            isChartsUpdating,
            isHourly,
            period,
        });

        return {
            IconSource,
            isHourly,
            isLoading,
            triggerDelayedLoading,
            isDelayedLoading,
            isExpanded,
            toggleExpandBarn,
            period,
        };
    },
});
</script>
