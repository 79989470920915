import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-white relative rounded-lg grid py-6 space-y-3 px-10 m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelpWidgetButton = _resolveComponent("HelpWidgetButton")!
  const _component_CloseModalButton = _resolveComponent("CloseModalButton")!
  const _component_MultiStepFormHeaderIcon = _resolveComponent("MultiStepFormHeaderIcon")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    "close-esc-event": "keydown",
    "close-button": false,
    "modal-class": "w-128 m-auto border-0",
    "mask-class": "grid",
    onClosing: _ctx.onModalClose
  }, {
    toggle: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    header: _withCtx((scope) => [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.form?.form?.help_widget_item && _ctx.currentStepIndex !== 0)
            ? (_openBlock(), _createBlock(_component_HelpWidgetButton, {
                key: 0,
                item: _ctx.form.form.help_widget_item,
                "icon-size-class": "size-7",
                class: "z-50 absolute top-[14.5px] right-[58px]"
              }, null, 8, ["item"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_CloseModalButton, {
        onClose: scope.close
      }, null, 8, ["onClose"])
    ]),
    body: _withCtx((scope) => [
      _createElementVNode("form", _hoisted_1, [
        (_ctx.header || _ctx.form.event)
          ? (_openBlock(), _createBlock(_component_MultiStepFormHeaderIcon, {
              key: 0,
              "icon-source": _ctx.header?.icon_url || _ctx.form.event?.icon_url || '',
              "is-final-step": _ctx.isFinalStep
            }, null, 8, ["icon-source", "is-final-step"]))
          : _createCommentVNode("", true),
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStep.component), {
              state: _ctx.form,
              "onUpdate:state": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form) = $event)),
              assignment: _ctx.computedAssignment,
              "onUpdate:assignment": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedAssignment) = $event)),
              header: _ctx.header,
              config: _ctx.currentStepConfig,
              onSubmit: _ctx.next,
              onGoToStep: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToStep($event))),
              onSkip: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToStep(_ctx.currentStepIndex + 2))),
              onBack: _ctx.previous,
              onClose: scope.close,
              onForceClose: ($event: any) => (_ctx.onForceClose(scope.close)),
              onSubmission: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('submission')))
            }, null, 40, ["state", "assignment", "header", "config", "onSubmit", "onBack", "onClose", "onForceClose"]))
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 3
  }, 8, ["onClosing"]))
}