<template>
    <div class="space-y-4">
        <div class="flex items-center justify-end gap-x-4">
            <ChartPeriodSelect v-model="period" :disabled="isFetching || isDelayedLoading" @update:model-value="triggerDelayedLoading" />
            <SwitchButton
                v-model="isHourly"
                :true-text="$t('views.monitoring.main.charts.hourlyLabel')"
                :false-text="$t('views.monitoring.main.charts.dailyLabel')"
                :disabled="isFetching || isDelayedLoading"
                @update:model-value="triggerDelayedLoading"
            />
        </div>
        <div v-if="isLoading" class="flex justify-center px-6 py-8 items-center space-x-2">
            <span>{{ $t('views.monitoring.main.charts.loadingText') }}</span>
            <Spinner class="h-6 w-6" />
        </div>
        <ExpandCollapseTransition>
            <div v-if="chartsData?.length" class="space-y-4">
                <div v-if="chartsData?.length" class="space-y-4">
                    <div v-for="(chart, chartIndex) in chartsData" :key="chart.id" class="relative w-full flex items-center justify-center border border-gray-200 rounded-md overflow-hidden p-3">
                        <div class="flex w-full items-center justify-center" :style="{ minHeight: chart.options?.chart?.height ? `${chart.options.chart.height}px` : '400px' }">
                            <transition name="fade-fast" mode="out-in">
                                <div v-if="isFetching" class="absolute inset-0 bg-white flex items-center justify-center">
                                    <Spinner class="h-12 w-12 mx-auto" />
                                </div>
                                <div v-else class="w-full">
                                    <EmptyChartState :options="chart.options">
                                        <GenericChart :options="(chart.options as any)" destroy-on-update @ready="chartInstances[chartIndex] = $event" @update="chartInstances[chartIndex] = $event" />
                                    </EmptyChartState>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </ExpandCollapseTransition>
        <div v-if="isError" class="text-center px-6 py-8">{{ $t('views.monitoring.main.charts.couldNotLoadChartsText') }}</div>
        <div v-else-if="!isLoading && chartsData?.length === 0" class="text-center px-6 py-8">{{ $t('views.monitoring.main.charts.noChartDataFound') }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import merge from 'lodash-es/merge';
import cloneDeep from 'lodash-es/cloneDeep';
import GenericChart from '@/components/charts/GenericChart.vue';
import EmptyChartState from '@/components/charts/EmptyChartState.vue';
import SwitchButton from '@/components/ui/SwitchButton.vue';
import { useMonitorOverviewCharts } from '@/plugins/store/actions/queries/monitoring/charts';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import Spinner from '@/components/ui/Spinner.vue';
import { ChartPeriod, IconSource } from '@/types';
import ChartPeriodSelect from '@/views/dashboard/monitoring/monitoring/ChartPeriodSelect.vue';

export default defineComponent({
    components: { GenericChart, EmptyChartState, SwitchButton, ExpandCollapseTransition, Spinner, ChartPeriodSelect },
    props: {
        sectionId: {
            type: Number as () => number,
            required: true,
        },
    },
    setup(props) {
        const isHourly = ref(true);
        const isChartsUpdating = ref(false);
        const locationId = computed(() => props.sectionId);
        const chartInstances = ref<Record<number, any>>({});
        const chartsData = ref<any>(null);

        function setChartExtremes(event: any, updatedChartIndex: number) {
            if (event.trigger !== 'navigator' || isChartsUpdating.value) {
                return;
            }

            isChartsUpdating.value = true;

            for (const chartId in chartInstances.value) {
                if (chartId.toString() !== updatedChartIndex.toString() && chartInstances.value[chartId]) {
                    chartInstances.value?.[chartId]?.xAxis?.[0]?.setExtremes(event.min, event.max);
                    chartInstances.value?.[chartId]?.redraw();
                }
            }

            isChartsUpdating.value = false;
        }

        function mergeOptions(chartOptions: any, chartInstanceIndex: number) {
            if (!chartOptions) {
                return null;
            }

            const clonedOptions = cloneDeep(chartOptions);
            const mergedOptions = merge(clonedOptions, { chart: { height: 400 } });

            mergedOptions.xAxis = mergedOptions.xAxis || [];
            mergedOptions.xAxis[0] = {
                ...(mergedOptions.xAxis[0] || {}),
                events: {
                    setExtremes: (event: any) => setChartExtremes(event, chartInstanceIndex),
                },
            };

            return mergedOptions;
        }

        const period = ref(ChartPeriod.THREE_MONTHS);

        const {
            data: charts,
            isLoading,
            isFetching,
            isError,
        } = useMonitorOverviewCharts({
            sectionId: locationId,
            hourly: isHourly,
            period,
        });

        watch(
            () => charts.value,
            (newCharts) => {
                if (!newCharts) {
                    return;
                }

                const mappedCharts: any[] = [];

                newCharts.charts.forEach((c: any, index: number) => {
                    chartInstances.value[index] = chartInstances.value[index] || null;

                    mappedCharts.push({
                        options: mergeOptions(c.options, index),
                    });
                });

                chartsData.value = mappedCharts;
            },
            { immediate: true }
        );

        const isDelayedLoading = ref(false);

        function triggerDelayedLoading() {
            isDelayedLoading.value = true;

            setTimeout(() => {
                isDelayedLoading.value = false;
            }, 800);
        }

        return {
            chartsData,
            chartInstances,
            isHourly,
            isLoading,
            isFetching,
            isError,
            isDelayedLoading,
            triggerDelayedLoading,
            period,
            IconSource,
        };
    },
});
</script>
