import { Treatment } from './Treatment';

type MonitoringDataHeader = {
    name?: string | null;
    icon_id: number;
    is_visible: boolean;
};

export type MonitoringDataHeaders = {
    farm_name?: string | null;
    farm_id: number;
    barn_id: number;
    barn_name?: string | null;
    pigs: MonitoringDataHeader & {
        number?: number | null;
    };
    avg_weight: MonitoringDataHeader & {
        number?: number | null;
    };
    mortality: MonitoringDataHeader & {
        number?: number | null;
    };
    due_treatments: MonitoringDataHeader & {
        treatments?: Treatment[] | null;
    };
    water_consumption: MonitoringDataHeader & {
        alarm_severity?: number | null;
    };
    feeding_system: MonitoringDataHeader & {
        alarm_severity?: number | null;
    };
    check_in: MonitoringDataHeader & {
        max_elapsed_hours?: number | null;
    };
    retention_date: MonitoringDataHeader;
};

export type MonitoringDataRow = {
    section_id: number;
    section_name?: string | null;
    number_of_pigs?: number | null;
    avg_weight?: number | null;
    mortality?: number | null;
    due_treatments: { treatments?: Treatment[] | null };
    water_consumption: { ids?: number[] | null; max_severity_level: number };
    feeding_sys_runtime: { ids?: number[] | null; max_severity_level: number };
    check_in: { value?: string | null; is_red: boolean };
};

export type MonitoringDataBarn = {
    headers: MonitoringDataHeaders;
    rows: MonitoringDataRow[] | null;
};

export type MonitoringData = MonitoringDataBarn[];

/* eslint-disable */
export enum ChartPeriod {
    THREE_MONTHS = '3_months',
    SIX_MONTHS = '6_months',
    NINE_MONTHS = '9_months',
    TWELVE_MONTHS = '12_months',
}
