<template>
    <div ref="intersectionTarget" class="border border-gray-200 rounded-md overflow-hidden">
        <div class="relative w-full flex items-center justify-center">
            <div class="absolute z-10 top-0 left-0 py-4 px-6">
                {{ row.section_name }}
            </div>
            <div class="flex w-full items-center justify-center" :style="{ minHeight: chartOptions?.chart?.height ? `${chartOptions.chart.height}px` : '400px' }">
                <transition name="fade-fast" mode="out-in">
                    <div v-if="!hasIntersected || isLoading" class="absolute inset-0 bg-white flex items-center justify-center">
                        <Spinner class="h-12 w-12 mx-auto" />
                    </div>
                    <div v-else class="w-full">
                        <EmptyChartState :options="chartOptions">
                            <GenericChart :options="(chartOptions as any)" destroy-on-update @ready="setChartInstance" @update="setChartInstance" />
                        </EmptyChartState>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject, Ref, onBeforeUnmount, computed, ref } from 'vue';
import merge from 'lodash-es/merge';
import cloneDeep from 'lodash-es/cloneDeep';
import { useIntersectionObserver } from '@vueuse/core';
import { WATER_CHARTS_INJECTION_KEY } from './injectionKeys';
import { MonitoringDataRow, IconSource, ChartPeriod } from '@/types';
import { useMonitorWaterConsumptionChart } from '@/plugins/store/actions/queries/monitoring/charts';
import EmptyChartState from '@/components/charts/EmptyChartState.vue';
import GenericChart from '@/components/charts/GenericChart.vue';
import Spinner from '@/components/ui/Spinner.vue';

export default defineComponent({
    components: { EmptyChartState, GenericChart, Spinner },
    props: {
        row: {
            type: Object as () => MonitoringDataRow,
            required: true,
        },
    },
    setup(props) {
        const intersectionTarget = ref<HTMLDivElement | null>(null);
        const hasIntersected = ref(false);

        const { stop } = useIntersectionObserver(
            intersectionTarget,
            ([{ isIntersecting }]) => {
                if (isIntersecting) {
                    hasIntersected.value = true;
                    stop();
                }
            },
            {
                rootMargin: '200px 0px 200px 0px',
            }
        );

        const { chartInstances, isChartsUpdating, isHourly, period } = inject(WATER_CHARTS_INJECTION_KEY) as {
            chartInstances: Ref<Record<number, any>>;
            isChartsUpdating: Ref<boolean>;
            isHourly: Ref<boolean>;
            period: Ref<ChartPeriod>;
            selectedChartExtremes: Ref<any>;
        };

        function setChartExtremes(event: any, updatedChartId: number) {
            if (event.trigger !== 'navigator' || isChartsUpdating.value) {
                return;
            }

            isChartsUpdating.value = true;

            for (const chartId in chartInstances.value) {
                if (chartId.toString() !== updatedChartId.toString() && chartInstances.value[chartId]) {
                    chartInstances.value?.[chartId]?.xAxis?.[0]?.setExtremes(event.min, event.max);
                    chartInstances.value?.[chartId]?.redraw();
                }
            }

            isChartsUpdating.value = false;
        }

        function setChartInstance(instance: any) {
            chartInstances.value[props.row.section_id] = instance;
        }

        function mergeOptions(options: any) {
            if (!options) {
                return null;
            }

            const clonedOptions = cloneDeep(options);
            const mergedOptions = merge(clonedOptions, { chart: { height: 400 } });

            mergedOptions.title = mergedOptions.title || {};
            mergedOptions.title.text = props.row.section_name;
            mergedOptions.title.style = {
                ...mergedOptions.title.style,
                fontSize: '16px',
                color: 'transparent',
            };
            mergedOptions.xAxis = mergedOptions.xAxis || [];
            mergedOptions.xAxis[0] = {
                ...(mergedOptions.xAxis[0] || {}),
                events: {
                    setExtremes: (event: any) => setChartExtremes(event, props.row.section_id),
                },
            };

            return mergedOptions;
        }

        const locationId = computed(() => props.row.section_id);

        const { data: chartData, isLoading } = useMonitorWaterConsumptionChart(
            {
                sectionId: locationId,
                hourly: isHourly,
                period,
            },
            {
                enabled: hasIntersected,
            }
        );

        const chartOptions = computed(() => {
            if (!chartData.value) {
                return null;
            }

            return mergeOptions(chartData.value.options);
        });

        onBeforeUnmount(() => {
            for (const chartId in chartInstances.value) {
                if (chartInstances.value[chartId]) {
                    delete chartInstances.value[chartId];
                }
            }
        });

        return {
            chartOptions,
            isLoading,
            setChartInstance,
            intersectionTarget,
            hasIntersected,
            period,
            IconSource,
        };
    },
});
</script>
